<template>
  <div class="product align-items-center card text-center carousel__item">
    <div v-if="product.actionPrice" class="discount-wrapper">
      <svg class="discount-banner-icon"></svg>
    </div>
    <span class="discount-amount">{{product.actionDiscount}}%</span>
    <div class="product-image-container">
      <img
      class="product-image"
      :src="imageUrl"
      :alt="product.name + ' ' + product.description"
      />
    </div>
    <!-- <div class="discount d-flex justify-content-center align-items-center">25%</div>
    <div class="product-image-container">
      <img
      class="product-image"
      :src="imageUrl"
      :alt="product.name + ' ' + product.description"
      />
    </div> -->
    <div class="product-info">
      <h3 class="product-name">{{ product.name }}</h3>
      <p class="category-name">{{ product.categoryName }}</p>
      <div class="d-flex flex-column">
        <p v-if="product.actionPrice" class="old-price">{{ product.price + ",00 RSD" }}</p>
        <h4 class="action-price" v-if="product.actionPrice">
            {{ product.actionPrice + ",00 RSD" }}
        </h4>
        <h4 class="regular-price" v-if="!product.actionPrice">
           {{ product.price + ",00 RSD" }}
        </h4>
      </div>
    </div>
    <div class="btn edit-btn" @click="onEdit()"><svg class="edit-icon"></svg>Izmeni</div>
    <div v-if="!isDeleted" class="btn delete-btn" @click.stop="openDeleteModal()"><svg class="delete-icon"></svg>Obrisi</div>
    <div v-if="isDeleted" class="btn delete-btn" @click.stop="openRestoreModal()"><svg class="delete-icon"></svg>Povrati</div>
  </div>
  <BaseModal @confirm="deleteProduct" @cancel="closeDeleteModal" header="Da li si siguran da zelis da obrises proizvod?" v-if="isDeleteModalOpen" />
  <BaseModal @confirm="restoreProduct" @cancel="closeRestoreModal" header="Da li si siguran da zelis da povratis proizvod?" v-if="isRestoreModalOpen" />
</template>

<script>
import { useRouter } from "vue-router";
import BaseModal from '../../../components/shared/BaseModal.vue'
import { ref } from 'vue';
export default {
  components: { BaseModal },
  emits: ["delete", "restore"],
  props: {
    product: Object,
    isDeleted: Boolean
  },
  setup(props, context){
    const router = useRouter();
    const imageUrl = process.env.VUE_APP_API_URL + "/" + props.product.imageUrl;
    const isDeleteModalOpen = ref(false);
    const isRestoreModalOpen = ref(false);

    const deleteProduct = () => {
        closeDeleteModal();
        context.emit('delete');
    }

    const openDeleteModal = () => {
        isDeleteModalOpen.value = true;
    }

    const closeDeleteModal = () => {
        isDeleteModalOpen.value = false;
    }

    const openRestoreModal = () => {
      isRestoreModalOpen.value = true;
    }

    const closeRestoreModal = () => {
      isRestoreModalOpen.value = false;
    }

    const restoreProduct = () => {
      closeRestoreModal();
      context.emit('restore');
    }

    const onEdit = () => {
        router.push({name: 'Edit', params: {'id': props.product.id}})
    }

    return {
      imageUrl,
      isDeleteModalOpen,
      isRestoreModalOpen,
      deleteProduct,
      openDeleteModal,
      closeDeleteModal,
      openRestoreModal,
      closeRestoreModal,
      restoreProduct,
      onEdit
    };
  }
};
</script>

<style scoped lang="scss">

.card {
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 22px;
  @include noselect;

  &:hover{
    box-shadow: 0 0px 8px rgba(0,0,0,0.25);
    transition: box-shadow 0.3s ease-in-out;
  }

}

  .discount-wrapper {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 108px;
    height: 108px;
  }

    .discount-banner-icon{
      @include icon;
      width: 108px;
      height: 108px;
      background-position: 0% 0%;
      @include background-svg($discount-icon);
    }

    .discount-amount{
      position: absolute;
      left: 22px;
      top: 22px;
      z-index: 10;
      color: white;
      font-weight: $bold-font-weight;
      font-size: $h4-size;
      transform: rotate(-45deg);
    }

  .product-image-container{
    margin-top: 47px;
    position: relative;
    display:flex;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
  
    .product-image {
      object-fit: cover;
      background-size: contain;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    }

  .product-info {
    margin-top: 10px;
    min-height: 277px;
  }

    .product-name{
      margin-bottom: 10px !important;
    }

    .category-name {
      color: color(darkgrey);
      margin-bottom: 30px;
    }

    .action-price {
      color: color(red);
      font-weight: $light-font-weight !important;
    }

    .old-price {
      text-decoration: line-through;
      color: color(darkgrey);
      margin-bottom: 5px;
    }

    .regular-price {
      margin-top: 29px !important;
      color: color(darkgrey);
      font-weight: $light-font-weight !important;
    }
// .card {
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
//   border: 0;
//   border-radius: 0;
//   padding: 0 30px;
//   width: 100%;
// }

//   .discount {
//     position: absolute;
//     width: 92px;
//     height: 37px;
//     background: color(red);
//     color: white;
//     font-weight: $bold-font-weight;
//     margin: 0px auto;
//   }

//   .product-image-container{
//     margin-top: 47px;
//     position: relative;
//     display:flex;
//     width: 100%;
//     height: 0;
//     padding-bottom: 100%;
//   }
  
//     .product-image {
//       object-fit: cover;
//       background-size: contain;
//       position: absolute;
//       background-position: center;
//       background-repeat: no-repeat;
//       height: 100%;
//       width: 100%;
//     }

//   .product-info {
//     margin-top: 10px;
//     min-height: 127px;
//   }

//     .product-name{
//       margin-bottom: 10px !important;
//     }

//     .category-name {
//       font-weight: $bold-font-weight;
//       color: color(darkgrey);
//       margin-bottom: 30px;
//     }

//     .action-price {
//       color: color(red);
//       margin-bottom: 30px !important;
//       font-weight: $light-font-weight !important;
//     }

//     .old-price {
//       text-decoration: line-through;
//       color: color(darkgrey);
//       margin-bottom: 5px;
//     }

    .delete-btn {
        width: 100%;
        background: color(darkgrey);
        color: white;
        font-weight: $bold-font-weight;
        border-radius: 0 !important;
        margin-bottom: 30px;
        margin-top: 8px;
        text-align: center;
    }

      .delete-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        @include background-svg($admin-delete-icon);
        margin-right: 10px;
      }

    .edit-btn {
        width: 100%;
        background: color(blue);
        color: white;
        font-weight: $bold-font-weight;
        border-radius: 0 !important;
        text-align: center;
    }

      .edit-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        @include background-svg($admin-edit-icon);
        margin-right: 10px;
      }

    .edit-btn:hover, .delete-btn:hover {
      color:  white;
    }
</style>
