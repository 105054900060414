<template>
    <base-backdrop />
    <div class='custom-modal'>
      <header class='modal-header'>
        <h3>{{header}}</h3>
      </header>
      <slot></slot>
      <footer class="modal-footer">
          <button class="btn confirm-btn" @click="onConfirm">Confirm</button>
          <button class="btn cancel-btn" @click="onCancel">Cancel</button>
      </footer>
    </div>
</template>

<script>
import BaseBackdrop from './BaseBackdrop.vue'
export default {
  emits: ["confirm", "cancel"],
  props: {
    header: String,
  },
  components: { BaseBackdrop },
  setup(props, context) {
    const onConfirm = () => {
      context.emit("confirm")
    }

    const onCancel = () => {
      context.emit("cancel");
    }

    return {
      onConfirm,
      onCancel
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-modal {
  z-index: 800;
  position: fixed;
  top: 22vh;
  left: 20%;
  width: 60vw;
  background: color(lightgrey);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  // border-radius: 8px;
}

.modal-header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: white;
  color: #e7ecef;
}

    .modal-header h2 {
    margin: 0.5rem;
    color: color(blue);
    }

    .modal-footer {
      padding: 1rem 0.5rem;
      padding-top: 2rem!important;
    }

    .confirm-btn {
      background: color(blue);
      color: white;
    }

    .cancel-btn {
      background: color(red);
      color: white;
    }
</style>